import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { Button } from "../global/atoms/Button"

const ListItem = styled.li`
  margin: 0 10px 20px;

  @media ${maxD.tabletL} {
    margin: 0 7px 14px;
  }
`

const Category = ({ data, setCategory }) => {
  const { name, slug, id } = data

  return (
    <ListItem key={id}>
      <button onClick={() => setCategory(slug)}>
        <Button theme="green" as="span">
          {name}
        </Button>
      </button>
    </ListItem>
  )
}

export { Category }
