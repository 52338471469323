import React, { useRef } from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { Category } from "./Category"
import { UnstyledList } from "../../components/global/common/essentials"

const List = styled(UnstyledList)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${minD.tabletL} {
    padding: 0 8vw;
  }

  @media ${maxD.mobileL} {
    display: none;
  }
`

const SelectContainer = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;

    width: 1rem;
    height: 100%;

    top: 0;
    right: 4.6rem;

    background: var(--select-arrow);
    background-size: contain;
  }
`

const Select = styled.select`
  display: block;

  width: 70vw;
  padding: 1.2rem 1.6rem;
  margin: 0 auto;
  background-color: var(--main-green);

  border-radius: 2rem;

  color: #fff;

  @media ${minD.mobileL} {
    display: none;
  }
`

const CaseStudiesCategories = ({ categories, setCategory }) => {
  const select = useRef(null)

  const handleCategorySelect = () => {
    const selectValue = select.current.value
    setCategory(selectValue)
  }

  return (
    <header>
      <List>
        {categories &&
          categories.map((category, i) => {
            const { name, slug, id } = category

            return (
              (slug !== "featured" || i === 1) && (
                <Category
                  data={{
                    id,
                    slug,
                    name,
                  }}
                  setCategory={setCategory}
                  key={id}
                />
              )
            )
          })}
      </List>
      <SelectContainer>
        <Select onChange={() => handleCategorySelect()} ref={select}>
          <option key="choose" value="all">
            Choose category
          </option>

          {categories &&
            categories.map((category, i) => {
              const { name, slug, id } = category

              return (
                (slug !== "featured" || i === 1) && (
                  <option key={id} value={slug}>
                    {name}
                  </option>
                )
              )
            })}
        </Select>
      </SelectContainer>
    </header>
  )
}

export { CaseStudiesCategories }
