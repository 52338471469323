import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { MainTitle } from "../global/common/text"
import {
  BannerContainer,
  BannerWrapper,
  LineWrapperWithBottomSpace,
} from "../global/common/containers"

import Nav from "../global/nav/Nav"
import { Reviews } from "../global/Reviews"

const CaseStudiesBannerWrapper = styled(BannerWrapper).attrs({
  as: LineWrapperWithBottomSpace,
})`
  @media ${maxD.laptop} {
    flex-direction: column;
    align-items: flex-start;

    &::after {
      top: -30px;
    }
  }
`

const CaseStudiesTitle = styled(MainTitle)`
  width: 100%;
`

const ReviewsContainer = styled.figure`
  width: calc(520px + 160px);
  flex-shrink: 0;
  padding: 0 30px 0 var(--section-spacing);

  @media ${maxD.laptopL} {
    width: calc(490px + 100px);
    padding: 0 30px 0 70px;
  }

  @media ${maxD.laptop} {
    width: min(400px, 100%);
    padding: 0;
    margin-top: -20px;
  }
`

function CaseStudiesHeader({ pageContext, title }) {
  const header = {
    gray: title.textGray,
    green: title.textGreen,
  }

  return (
    <BannerContainer
      style={{
        "--laptop-padding": "180px 0 0",
      }}
    >
      <Nav pageContext={pageContext} />
      <CaseStudiesBannerWrapper relative flex alignItems="fe">
        <CaseStudiesTitle as="h1" style={{ lineHeight: "1.5" }}>
          {`${header.gray} `}
          <span>{header.green}</span>
        </CaseStudiesTitle>
        <ReviewsContainer>
          <Reviews />
        </ReviewsContainer>
      </CaseStudiesBannerWrapper>
    </BannerContainer>
  )
}

export { CaseStudiesHeader }
